.Aduview {
  max-width: "1000px"
}

.AduView form {
  padding-bottom: 15px;
}

.AduView .Aduview {
  padding: 80px 0;
  text-align: center;
}

.AduView form textarea {
  height: 300px;
  font-size: 24px;
}

.AduView .Aduview div {
  padding-top: 20px;
}
.AduView .Aduview div a:first-child {
  margin-right: 20px;
}

.AduView .Aduview h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.AduView .Aduview p {
  color: #999;
}

a:hover {
  text-decoration: none;
}