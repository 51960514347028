html {
  font-size: unset !important;
}

.App {
  margin-top: 15px;
  min-height: 100%;
  height: fit-content;
  width: 100%;
    position: absolute;
}

.App .navbar-brand {
  font-weight: bold;
}

a:hover {
  text-decoration: none!important;
}

body::-webkit-scrollbar {
  width: 0.5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}